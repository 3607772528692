import { useRecoilState, useRecoilValue } from 'recoil';
import { GameData } from '../common/GameData';

import './Policy.css'

function Policy({ info }) {
    const Company = "우리네 게임즈";
    const Company_IncludeURL = "‘http://www.urngames.com’이하 ‘" + Company + "’";
    const CS_Mail = "gonggushin@gmail.com";
    const gameData = useRecoilValue(GameData);

    // console.log(info.package);
    if (null != info) {
        // console.log(info.package);
        const game = gameData[info.package];

        let title = (null == game
            ? "쀄에엑~~"
            : game.title.kr
        );        
        title = "<" + title + ">";

        return (
            <div>
                <h2 className='title'>구글 플레이 스토어 개인정보 처리 방침</h2>
                
                <div>
                    {title}({Company_IncludeURL})는 처리하는 개인정보를 매우 중요하게 생각하며,
                    개인정보의 보호와 관련하여 "개인정보보호법" 등 관련법 상의 개인정보 보호규정을 준수하고 있습니다.<br />
                    '{Company}'는 개인정보가 남용되거나 유출되지 않도록 최선을 다할 것이며,
                    이에 다음과 같이 개인정보보호법 제30조에 따라 정보주체의 개인정보를 보호하고
                    이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침을 수립,공개 합니다.
                </div>

                <p className='paragraph'>
                    <span className='head'>1. 개인정보의 처리 목적</span><br />
                    {title}({Company_IncludeURL}) 다음의 목적을 위하여 개인정보를 처리하고 있으며,
                    본 앱은 일체의 회원가입 및 개인정보 제공을 요구 하지 않습니다.<br />
                    다만 구글플레이에서 광고와 분석(Analytics)을 위하여 사용자에게 부여하는
                    Android Advertising ID가 앱 내 광고를 위한 Google AdMob 과 사용 분석을 위한 Google Analytics에 제공되며,
                    이에 대한 절차 및 책임은 Google AdMob과 Google Analytics에 있습니다.<br />
                    '{Company}' Android Advertising ID와 관련하여 구글플레이의 지침을 철저히 준수하며,
                    이를 일체 앱에 저장하거나 서버에 전달 또는 저장하지 않습니다.<br />
                    사용자 분석 데이터는 Google Analytics에 의해 완벽하게 익명처리됩니다.
                </p>

                <p className='paragraph'>
                    <span className='head'>2. 정보주체의 권리,의무 및 그 행사방법</span><br />
                    이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.<br />
                    정보주체는 {title}({Company_IncludeURL})에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br />
                    1) 개인정보 열람요구<br />
                    2) 오류 등이 있을 경우 정정 요구<br />
                    3) 삭제요구<br />
                    4) 처리정지 요구<br />
                    정보를 전혀 수집 하지 않으므로, 모두 사용자가 직접 앱을 삭제 하여 처리 할 수 있습니다.
                </p>

                <p className='paragraph'>
                    <span className='head'>3. 처리하는 개인정보의 항목 작성</span><br />
                    {title}({Company_IncludeURL}) 다음의 개인정보 항목을 처리하고 있습니다.<br />
                    1) 필수항목을 보유하고 있지 않습니다.
                </p>

                <p className='paragraph'>
                    <span className='head'>4. 개인정보의 파기</span><br />
                    {title}({Company_IncludeURL})은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다.
                    파기의 절차, 기한 및 방법은 다음과 같습니다.<br />
                    1) 구글플레이에서 광고와 분석(Analytics)을 위하여 Android 광고 ID를 수집하고 전송<br />
                    2) 위 권한을 사용하여 어떠한 정보도 수집하지 않고, 처리 하지 않습니다.
                </p>

                <p className='paragraph'>
                    <span className='head'>5. 개인정보의 안전성 확보 조치</span><br />
                    {title}({Company_IncludeURL}) 개인정보보호법 제29조에 따라
                    다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.<br />
                    1) 내부관리계획의 수립 및 시행<br />
                    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br /><br />

                    2) 개인정보에 대한 접근 제한<br />
                    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여
                    개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br /><br />

                    3) 비인가자에 대한 출입 통제<br />
                    개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
                </p>

                <p className='paragraph'>
                    <span className='head'>6. 개인정보 보호책임자 작성</span><br />
                    {title}({Company_IncludeURL}) 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고,
                    개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br /><br />

                    ▶ 개인정보 보호책임자<br />
                    성명 : {Company}<br />
                    직책 : 대표<br />
                    직급 : 대표<br />
                    연락처 : {CS_Mail}<br />
                    ※ 개인정보 보호 담당부서로 연결됩니다.<br /><br />

                    ▶ 개인정보 보호 담당부서<br />
                    부서명 : 개발<br />
                    담당자 : 대표<br />
                    연락처 : {CS_Mail}<br />
                    정보주체께서는 ‘{Company}’ 의 서비스(또는 사업)을 이용하시면서
                    발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.<br />
                    ‘{Company}’ 는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br />
                </p>

                <p className='paragraph'>
                    <span className='head'>7. 권익침해 구제방법</span><br />
                    정보주체는 개인정보침해로 인한 구제를 받기 위하여
                    개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
                    이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br />
                    1) 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br />
                    2) 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br />
                    3) 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br />
                    4) 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)<br />
                    "개인정보보호법" 제35조(개인정보의 열람), 제36조(개인정보의 정정,삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여
                    공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br />
                    ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.<br />
                </p>

                <p className='paragraph'>
                    <span className='head'>8. 개인정보 처리방침 변경</span><br />
                    1). 이 개인정보처리방침은 시행일로부터 적용되며,
                    법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.<br />
                    2). 이 개인정보처리방침은 2022년 1월 21일부터 적용 됩니다.<br />
                </p>
            </div>
        );
    }
    else {
        console.log("None Data");
        return (<div>None Data</div>);
    }
}

export default Policy;
