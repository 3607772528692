import { Link } from 'react-router-dom';

import './Home.css';

function Home() {
    return (
        <div className="container">
            <img src="/img/logo.png" />
            <div className='contsbox'>
                <p className="title">TaeKwon Pang</p>
                <div>
                    우리네게임즈에서 첫 출시한 “태권팡”은 어린이 여러분들의 두뇌 개발과 순발력을 높이기 위해 만들어진 게임으로,<br />
                    태권도를 응용하여 흰띠부터 검은띠까지의 난이도에 따라 문제를 푸는 게임입니다.<br />
                    개인전 뿐만 아니라 단체전에서 친구 또는 부모님과 함께 재미있게 즐길 수 있도록 만들어진 게임입니다.
                </div>
                <div className='privacy'>
                    <Link to="/policy" state={{package: "com.urngames.taekwonpang"}}>개인정보처리방침</Link>
                    {/* <Link to="/policy" state={{package: "com.yamyyamy.tamago"}}>개인정보처리방침</Link> */}
                </div>
                <a className='mailicon' href="mailto:gonggushin@gmail.com" target="_blank">
                    <i className="fa fa-envelope fa-2x"></i>
                </a>
            </div>
        </div>
    );
}

export default Home;
