import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Policy from './Policy'

function PolicyLink() {
    const locationState = useLocation().state;
    const navigate = useNavigate();

    useEffect(() => {
        if(null == locationState) {
            navigate("/");
        }

        return function cleanUp() {
            // console.log("Unmount");
        }
    });

    return <Policy info={locationState} />
}

export default PolicyLink;
