import { useEffect } from "react";
import {
    // useLocation,
    useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';

import { GameData } from '../common/GameData';
import Policy from './Policy'

function PolicyQuery() {
    // const location = useLocation();
    // console.log(location.search);

    const [ searchParams ] = useSearchParams();
    const pack = searchParams.get("package");
    // console.log(pack);

    const navigate = useNavigate();
    
    const game = useRecoilValue(GameData)[pack];

    useEffect(() => {

        if(null == pack || null == game) {
            navigate("/");
        }

        return function cleanUp() {
            // console.log("Unmount");
        }
    });

    return <Policy info={{package: pack}} />
}

export default PolicyQuery;
