import { HashRouter, Routes, Route} from "react-router-dom";
import { RecoilRoot } from 'recoil';

import Home from './page/Home';
import PolicyLink from './page/PolicyLink';
import PolicyQuery from './page/PolicyQuery';

import Version from './api/Version';

function App() {
  return (
    <RecoilRoot>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/policy" element={<PolicyLink />} />
          <Route path="/policy_query" element={<PolicyQuery />} />

          <Route path="/api/version" element={<Version />} />
        </Routes>
      </HashRouter>
    </RecoilRoot>
  );
}

export default App;
