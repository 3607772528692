import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    // useRecoilState,
    useRecoilValue } from 'recoil';

import { GameData } from '../common/GameData';

function Version() {
    const [searchParams] = useSearchParams();
    const pack = searchParams.get("package");
    const version = searchParams.get("version");
    const platform = searchParams.get("platform");
    // console.log(pack, version, platform);

    const navigate = useNavigate();

    const game = useRecoilValue(GameData)[pack];

    let marketVersion = 0;

    useEffect(() => {

        if (null == pack
            || null == version
            || null == platform

            || null == game) {
            navigate("/");
        }



        if (null == marketVersion)
            navigate("/");

        return function cleanUp() {
            // console.log("Unmount");
        }
    });

    if ("aos" == platform)
        marketVersion = game.version.aos;
    else if ("ios" == platform)
        marketVersion = game.version.ios;

    return (
        // <div>
        //     <div>{pack}</div>
        //     <div>{version}</div>
        //     <div>{platform}</div>
        //     <div>{marketVersion}</div>
        // </div>
        marketVersion
    );
}

export default Version;
